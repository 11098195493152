<template>
  <v-container
    fluid
    class="component-container custom-background"
    id="biography"
  >
    <!--  md and lg devices only  -->
    <v-row
      no-gutters
      align-content="center"
      style="width: 100%"
      class="fill-height hidden-sm-and-down"
    >
      <v-col class="col-4 fill-height">
        <p
          class="offset-2 text-h4 font-weight-black"
          style="color: rgb(248, 196, 113); margin-top: 10vh"
        >
          WOULD YOU<br />COME WITH US ?
        </p>
        <v-img
          :src="require('../../public/assets/icons/logo-black.png')"
          style="
            aspect-ratio: 1;
            max-height: 40vh;
            margin-left: 3vw;
            margin-top: 15vh;
          "
        ></v-img>
      </v-col>

      <v-col class="col-8 justify-center text-center" align-self="center">
        <p
          class="font-weight-bold text-pre-wrap text-center text-h5"
          style="color: rgb(60, 60, 60); margin-right: 2vw; margin-top: 25vh"
        >
          Mr Peabody est un power trio de rock alternatif, créé en 2018 à
          Boulogne-sur-Mer.
        </p>
        <p
          class="font-weight-medium text-justify text-pre-wrap text-h6"
          style="color: rgb(70, 70, 70); margin-right: 2vw; margin-top: 5vh"
        >
          Mr Peabody se compose d'Emilien GENDRON à la batterie, de Louis
          CARON à la basse et de Valentin MANTEZ à la guitare et au chant.
          Notre musique s'inspire d'un large éventail d'influences musicales
          telles que Jimi Hendrix, Rory Gallagher, Les Red Hot Chili Peppers,
          MUSE, Franz Ferdinand, ou encore Simple Minds. Ce qui définit Mr
          Peabody, ce sont des compositions uniques mélangeant différents
          horizons musicaux sans pour autant se restreindre à un style
          particulier.
        </p>
        <p
          class="font-weight-bold text-pre-wrap text-center text-h6"
          style="color: rgb(60, 60, 60); margin-right: 2vw; margin-top: 5vh"
        >
          So, would you come with us ?
        </p>
        <v-btn
          @click="openPDF"
          style="color: rgb(60, 60, 60); margin-right: 2vw; margin-top: 5vh"
        >
          Télécharger le dossier de presse
        </v-btn>
      </v-col>
    </v-row>

    <!--  xs and sm devices only  -->
    <v-col class="hidden-md-and-up">
      <v-row no-gutters class="justify-space-around align-center">
        <v-col class="col-6">
          <p
            class="offset-1 text-h4 font-weight-black"
            style="color: rgb(248, 196, 113); margin-top: 10vh"
          >
            WOULD YOU<br />COME WITH US ?
          </p>
        </v-col>
        <v-col class="col-6">
          <v-img
            :src="require('../../public/assets/icons/logo-black.png')"
            contain
            class="mx-auto"
            style="aspect-ratio: 1; max-height: 30vh"
          />
        </v-col>
      </v-row>

      <v-spacer style="height: 10vh" />
      <v-row no-gutters justify="center">
        <p
          class="font-weight-bold text-pre-wrap text-center text-h6"
          style="color: rgb(60, 60, 60)"
        >
          Mr Peabody est un power trio de rock alternatif, créé en 2018 à
          Boulogne-sur-Mer.
        </p>
        <p
          class="font-weight-medium text-justify text-pre-wrap"
          style="color: rgb(70, 70, 70); max-width: 100vw; margin-right: 1vw"
        >
          Mr Peabody se compose d'Emilien GENDRON à la batterie, de Louis CARON
          à la basse et de Valentin MANTEZ à la guitare et au chant.
          Notre musique s'inspire d'un large éventail d'influences musicales
          telles que Jimi Hendrix, Rory Gallagher, Les Red Hot Chili Peppers,
          MUSE, Franz Ferdinand, ou encore Simple Minds. Ce qui définit Mr
          Peabody, ce sont des compositions uniques mélangeant différents
          horizons musicaux sans pour autant se restreindre à un style
          particulier.
        </p>
        <p
          class="font-weight-bold text-pre-wrap text-center"
          style="color: rgb(60, 60, 60); margin-right: 1vw"
        >
          So, would you come with us ?
        </p>
        <v-btn
          @click="openPDF"
          class="text-pre-wrap text-center"
          style="color: rgb(60, 60, 60); margin-right: 1vw"
        >
          Télécharger le dossier de presse
        </v-btn>
        <div v-if="showPdf">
          <iframe
            :src="pdfUrl"
            frameborder="0"
            width="100%"
            height="600px"
          ></iframe>
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "Biography",
  data() {
    return {
      showPdf: false,
    };
  },
  methods: {
    openPDF() {
      window.open(
        "../../assets/documents/dossier_de_presse_2023.pdf",
        "_blank"
      );
    },
  },
};
</script>

<style scoped></style>
