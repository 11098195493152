<template>
  <v-container fluid class="component-container custom-background" id="contact">
    <!--  Social networks / Spotify & Mail row  -->

    <v-row no-gutters class="hidden-sm-and-down justify-center align-center">
      <v-col class="col-6 container-col-flex px-8 pb-4">
        <v-carousel cycle hide-delimiters>
          <v-carousel-item
            v-for="item in galleryImgList"
            :key="item"
            v-bind:src="getImg(item)"
          />
        </v-carousel>
      </v-col>

      <v-col class="col-6 container-col-flex">
        <p
          class="text-lg-h5 text-xl-h5 text-md-h6 font-weight-black text-uppercase text-center"
        >
          Images, vidéos, et bien plus...
        </p>

        <p
          class="text-center text-pre-wrap mr-lg-10 mr-xl-10 ml-lg-10 ml-xl-10 mr-md-5 ml-md-5"
        >
          Rejoignez-nous sur nos réseaux sociaux pour suivre notre actualité et
          découvrir nos photos, vidéos, et bien plus encore !
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters class="hidden-sm-and-down mb-16">
      <!--   Social Networks   -->

      <v-col class="col-6 container-col-flex" style="flex-direction: column">
        <v-row class="container-row mt-10 mb-10">
          <v-btn
            @click="goToFacebook"
            text
            :x-large="$vuetify.breakpoint.lgAndUp"
          >
            <v-icon size="32">mdi-facebook</v-icon>
            &nbsp;&nbsp; Rejoignez-nous sur notre page Facebook !
          </v-btn>
        </v-row>

        <v-row class="container-row mb-10 mt-10">
          <v-btn
            @click="goToInstagram"
            text
            :x-large="$vuetify.breakpoint.lgAndUp"
          >
            <v-icon size="32">mdi-instagram</v-icon>
            &nbsp;&nbsp; Venez voir nos photos sur Instagram !
          </v-btn>
        </v-row>
      </v-col>

      <v-divider vertical inset></v-divider>

      <!--   Spotify & Mail   -->

      <v-col class="col-6 container-col-flex" style="flex-direction: column">
        <v-row class="container-row mt-10 mb-10">
          <v-btn
            @click="goToSpotify"
            text
            :x-large="$vuetify.breakpoint.lgAndUp"
          >
            <v-icon size="32">mdi-spotify</v-icon>
            &nbsp;&nbsp; Écoutez notre musique sur Spotify !
          </v-btn>
        </v-row>

        <v-row class="container-row mb-10 mt-10">
          <v-btn @click="goToMail" text :x-large="$vuetify.breakpoint.lgAndUp">
            <v-icon size="32">mail</v-icon>
            &nbsp;&nbsp; contact@mrpeabody.fr
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!-- SM AND DOWN -->

    <v-col class="hidden-md-and-up col-12 mb-4">
      <v-row class="container-row justify-center mx-1 mb-16 mt-10">
        <!-- Colonne pour le texte (taille écran petit) -->

        <v-col class="col-12 container-col-flex hidden-lg-and-up">
          <p
            class="text-lg-h5 text-xl-h5 text-md-h6 font-weight-black text-uppercase text-center"
          >
            Images, vidéos, et bien plus...
          </p>

          <p
            class="text-center text-pre-wrap mr-lg-10 mr-xl-10 ml-lg-10 ml-xl-10 mr-md-5 ml-md-5"
          >
            Rejoignez-nous sur nos réseaux sociaux pour suivre notre actualité
            et découvrir nos photos, vidéos, et bien plus encore !
          </p>
        </v-col>

        <v-col class="col-12 container-col-flex hidden-lg-and-up">
          <v-carousel cycle hide-delimiters :height="carouselHeight">
            <v-carousel-item
              v-for="item in galleryImgList"
              :key="item"
              v-bind:src="getImg(item)"
            />
          </v-carousel>
        </v-col>
      </v-row>

      <v-divider class="mb-10" />

      <v-row class="container-row justify-start ml-1 mr-1">
        <v-btn @click="goToFacebook" text small>
          <v-icon size="32">mdi-facebook</v-icon>
          &nbsp;&nbsp; Notre page Facebook
        </v-btn>
      </v-row>

      <v-row class="container-row justify-start ml-1 mr-1 mt-10">
        <v-btn @click="goToInstagram" text small>
          <v-icon size="32">mdi-instagram</v-icon>
          &nbsp;&nbsp; Notre Instagram
        </v-btn>
      </v-row>

      <v-row class="container-row justify-start ml-1 mr-1 mt-10">
        <v-btn @click="goToSpotify" text small>
          <v-icon size="32">mdi-spotify</v-icon>
          &nbsp;&nbsp; Notre musique sur Spotify
        </v-btn>
      </v-row>

      <v-row class="container-row justify-start ml-1 mr-1 mt-10 mb-10">
        <v-btn @click="goToMail" text small>
          <v-icon size="32">mail</v-icon>
          &nbsp;&nbsp; contact@mrpeabody.fr
        </v-btn>
      </v-row>
    </v-col>

    <v-row
      no-gutters
      class="footer-background"
      style="position: absolute; bottom: 0; width: 100vw"
    >
      <v-card-text
        class="white--text text-center font-weight-bold text-lg-h5 text-xl-h5 text-md-h6"
      >
        <strong>
          &copy; {{ new Date().getFullYear() }} &mdash; Mr Peabody
        </strong>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact",

  methods: {
    goToYtbChannel() {
      window.open("https://www.youtube.com/channel/UCTs897747G6MRfGso3wTYnQ");
    },
    goToFacebook() {
      window.open("https://www.facebook.com/MrPeabodyOfficiel");
    },
    goToInstagram() {
      window.open("https://www.instagram.com/wearemrpeabody/");
    },
    goToSpotify() {
      window.open(
        "https://open.spotify.com/artist/51IgMw4ZU63uoS3r9qF0rj?si=Hw7WXD0IQSiYS39JPQHbAw"
      );
    },
    goToMail() {
      window.open("mailto:contact@mrpeabody.fr", "mail");
    },

    getImg(img) {
      return require(`../../public/assets/images/gallery/${img}`);
    },
  },
  computed: {
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "220px";
        case "sm":
          return "340px";
        case "md":
          return "380px";
        case "lg":
          return "400px";
        case "xl":
          return "540px";
      }
    },

    galleryImgList() {
      return ["img1.jpg", "img2.jpg", "img3.jpg", "img4.jpg"];
    },
  },
};
</script>

<style scoped>
.container-col-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
</style>
