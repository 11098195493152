<template>
  <v-fade-transition>
    <ScrollTopComponent />
  </v-fade-transition>
</template>

<script>
import ScrollTopComponent from "./ScrollTopComponent";
export default {
  components: {
    ScrollTopComponent,
  },
};
</script>

<style></style>
