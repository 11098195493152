<template>
  <v-btn
    class="mx-2 my-10 bottom-right"
    elevation="8"
    icon
    size="large"
    color="rgba(255, 255, 255, 0.8)"
    @click="scrollTop"
    v-show="visible"
  >
    <v-icon large color="rgba(32, 32, 32, 1)" icon="mdi-arrow-up-bold">^</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollListener: function () {
      this.visible = window.scrollY > 400;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
</style>
