<template>
  <v-app>
    <v-main>
      <v-container fluid class="component-container">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
