<template>
  <v-container fluid class="component-container">
    <Home />

    <Biography />

    <Contact />

    <ScrollTopArrow />
  </v-container>
</template>

<script>
import ScrollTopArrow from "@/components/ScrollComponents/ScrollTopArrow";

import Home from "@/components/Home";
import Biography from "@/components/Biography";
import Contact from "@/components/Contact";

export default {
  name: "MainView",
  components: {
    ScrollTopArrow,

    Home,
    Biography,
    Contact,
  },
};
</script>
