import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/MainView";
//import GalleryView from "@/views/GalleryView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
  },
  {
    path: "/social",
    name: "Social",
    component: MainView,
  },
  {
    path: "/soundwave",
    name: "Soundwave",
    beforeEnter() {
      window.location.href =
        "https://music.imusician.pro/a/zxKaY2Tk/mr-peabody/soundwave/";
    },
  },
  /*{
    path: '/:catchAll(.*)',
    name: 'NotFound'
    redirect:
  }*/
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
