<template>
  <v-container
    fluid
    :class="{
      'home-container-mdup': $vuetify.breakpoint.mdAndUp,
      'home-container-smdown': $vuetify.breakpoint.smAndDown,
    }"
    id="home"
  >
    <!--   Navbar   -->

    <v-row no-gutters class="navbar-container hidden-sm-and-down">
      <p>
        <v-btn
          :large="$vuetify.breakpoint.lgAndUp"
          :small="$vuetify.breakpoint.mdAndDown"
          text
          color="white"
          class="navbar-btn"
          style="font-size: 1.25em"
          @click="scroll('biography')"
        >
          À Propos de Nous
        </v-btn>
      </p>

      <p>
        <v-btn
          :large="$vuetify.breakpoint.lgAndUp"
          :small="$vuetify.breakpoint.mdAndDown"
          text
          color="white"
          class="navbar-btn"
          style="font-size: 1.25em"
          @click="toArtistProfile"
        >
          Notre Musique
        </v-btn>
      </p>

      <p>
        <v-btn
          :large="$vuetify.breakpoint.lgAndUp"
          :small="$vuetify.breakpoint.mdAndDown"
          text
          color="white"
          class="navbar-btn"
          style="font-size: 1.25em"
          @click="scroll('contact')"
        >
          Nous contacter
        </v-btn>
      </p>
    </v-row>

    <!--  Navbar & Drawer for SM and down  -->

    <v-app-bar class="hidden-md-and-up" color="transparent">
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense style="padding-top: 10%">
        <v-list-item-group v-model="group" active-class="shades--text">
          <v-list-item>
            <v-list-item-title @click="toArtistProfile">
              Notre Musique
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="scroll('biography')">
              A propos de nous
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="scroll('contact')">
              Nous contacter
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-spacer style="height: 30vh" class="hidden-sm-and-down" />

    <v-spacer
      style="height: 30vh"
      v-if="orientation === 'portrait-primary'"
      class="hidden-md-and-up"
    />

    <v-spacer
      style="height: 20vh"
      v-if="orientation === 'landscape-primary'"
      class="hidden-md-and-up"
    />

    <v-row
      no-gutters
      class="text-container justify-center"
      style="max-width: 100vw"
    >
      <v-img
        :max-width="logoMaxWidth"
        src="../../public/assets/images/header-logo-desktop.png"
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    drawer: false,
    show: false,
    group: null,
    orientation: window.screen.orientation.type,
  }),

  computed: {
    logoMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "80vw";
        default:
          return "35vw";
      }
    },
  },

  watch: {
    group() {
      this.drawer = false;
      this.orientation = window.screen.orientation.type;
    },
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    if (this.$route.name === "Social") {
      document.getElementById("contact").scrollIntoView({
        behavior: "smooth",
      });
    }
  },
  methods: {
    handleOrientationChange() {
      this.orientation = window.screen.orientation.type;
    },

    toArtistProfile() {
      this.$router.push("Soundwave");
    },

    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.home-container-mdup {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
  background: linear-gradient(
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0.6) 75%,
      rgba(0, 0, 0, 0.2) 200%
    ),
    url("../../public/assets/images/home-bg-mdup.jpg") no-repeat fixed center
      center;
  background-size: cover;
}

.home-container-smdown {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
  background: linear-gradient(
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0) 150%
    ),
    url("../../public/assets/images/home-bg-smdown.jpg") no-repeat fixed center
      center;
  background-size: cover;
}

.navbar-container {
  justify-content: space-evenly;
  padding: 1vh 0 3vh 0;
}

.navbar-btn {
  border-radius: 8px 0 8px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
</style>
